<template>
  <v-dialog max-width="500" v-model="ver" persistent>
    <v-card>
      <v-card-text class="pb-2">
        <div>

          <v-row align="center">
            <v-col cols="12">
              <h3 class="font-weight-medium text-center info--text mb-3">Ingrese el número de años a revisar</h3>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12 " class="px-10 py-0">
              <v-select
                  v-model="select_vigencia"
                  :items="anhos_vigencia"
                  item-text="text"
                  item-value="anhos"
                  persistent-hint
                  return-object
                  single-line
              ></v-select>
            </v-col>
            <v-col cols="12" class="pt-0 text-center">

              <v-btn
                  class="ma-2"
                  outlined
                  color="info"
                  @click="hideDialog"
              >
                Confirmar
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalAgregarVigencia",
  props: {
    verDialog: {default: false, type: Boolean},
    time: {default: null},
    certificado: {default: null}
  },
  data() {
    return {
      ver: false,
      anhos_vigencia: [
        {anhos: 30, text: "30 Años (Predeterminado)"},
        {anhos: 35, text: "35 Años"},
        {anhos: 40, text: "40 Años"},
        {anhos: 45, text: "45 Años"},
        {anhos: 50, text: "50 Años"},
        {anhos: 55, text: "55 Años"},
        {anhos: 60, text: "60 Años"},
        {anhos: 65, text: "65 Años"},
        {anhos: 70, text: "70 Años"},
        {anhos: 75, text: "75 Años"},
        {anhos: 80, text: "80 Años"},

      ],
      select_vigencia: null
    }
  },

  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    ver(newVal) {
      if (!newVal) {
        this.hideDialog();
      }else{
        this.select_vigencia = this.anhos_vigencia[0]
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", this.select_vigencia, this.certificado);
    },
    irAlCarro() {
      this.$router.push("/carro");
    },
    irATramites() {
      this.$router.push("/tramites")
    }
  }
}
</script>

<style scoped>

</style>
