<template>
    <div class="pt-3">
        <form @submit.prevent="buscarNombreDeLaSociedad()">
            <div class="d-flex">
                <v-text-field
                        autofocus
                        hide-details
                        outlined
                        label="Nombre de la Sociedad"
                        dense
                        placeholder="Escriba aquí el nombre de una Sociedad o Empresa"
                        v-model="nombreSociedad"
                ></v-text-field>

                <v-btn
                        color="primary"
                        style="height: 38px"
                        type="submit"
                        :loading="btnCargando"
                        class="ml-2"
                >
                    <v-icon class="pr-1">mdi-magnify</v-icon>
                    Buscar
                </v-btn>

            </div>
            <div class="pt-4" v-if="entrandoABuscar">
                <v-alert
                        type="info"
                        outlined
                        border="left"
                        :icon="false"
                        class="text-center ">
                    <p class="mb-0 font-md info--text">
                        Aquí podrá encontrar los datos registrales de una Inscripción de Comercio indicando el nombre de la empresa/sociedad
                    </p>

                </v-alert>
            </div>

            <v-fade-transition>
                <panel-sociedades-encontradas
                        :lista-sociedades-encontradas="listaSociedadesEncontradas"
                        @seleccionarItem="$emit('seleccionarItem', $event)"
                        v-if="!entrandoABuscar"
                ></panel-sociedades-encontradas>
            </v-fade-transition>
        </form>
    </div>
</template>

<script>

import tituloEntity from "@/model/titulo/titulo.entity";
import PanelSociedadesEncontradas from "@/views/index/indices/PanelSociedadesEncontradas.vue";

export default {
    name: "TabNombreSociedad",
    components: {PanelSociedadesEncontradas},
    data() {
        return {
            nombreSociedad: "",
            btnCargando: false,
            entrandoABuscar: true,
            listaSociedadesEncontradas: [],
        }
    },

    methods: {
        buscarNombreDeLaSociedad() {
            this.btnCargando = true;
            console.log("enviar nombre de la sociedad", this.nombreSociedad);
            tituloEntity.getConsultaSociedades(this.nombreSociedad).then(res => {
                console.log(res)
                this.listaSociedadesEncontradas = res.obj;
                this.entrandoABuscar = false;
                this.btnCargando = false;
            })
        },
    },
}
</script>

<style scoped>
@media (max-width: 600px) {
    .phoneData {

    }
}
</style>