<template>
  <div>
    <!--    <v-row v-if="escritura != null && Number.parseInt(escritura.anho_maximo_registro_notaria) > escritura.anho && escritura.comuna === 'LA SERENA'">
          <v-col cols="12" md="5" class="d-flex align-center pb-3 pb-sm-3 pb-md-0 pt-3 ">
            <p class=" mb-0 pl-2"> Escritura pública </p>
          </v-col>
          <v-col cols="6" md="4" class="d-flex justify-center align-center py-0  pt-3">
            <v-btn class="px-0" style="min-width: 25px"
                   small
                   elevation="0"
                   height="26"
                   tile
                   outlined
                   color="primary"
                   @click="reducir(escritura)"
            >
              <v-icon small dark>
                mdi-minus
              </v-icon>
            </v-btn>
            <input type="text"
                   class="input-spin"
                   disabled
                   v-model="escritura.numero_copias"
            >
            <v-btn
                outlined
                class="px-0"
                style="min-width: 25px"
                small
                elevation="0"
                height="26"
                tile
                color="primary"
                @click="aumentar(escritura)"
            >
              <v-icon small dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="6" md="3" class="d-flex justify-center align-center py-0 pt-3">
            <span :style="{color: escritura.numero_copias > 0 ? 'inherit' : '#6b666694'}">
              <span v-if="showTotal(escritura) > 0"> {{ showTotal(escritura) | clp }}</span>
              <span v-if="showTotal(escritura) == 0">  Por cotizar</span>
            </span>
          </v-col>
        </v-row>-->
    <v-row :key="certificado.id_tipo_certificado" v-for="certificado in certificados">
      <v-col cols="12" v-if="show === null">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" md="5" class="d-flex align-center pb-3 pb-sm-3 pb-md-0 pt-0 ">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-if="certificado.id_tipo_certificado === registros.ID_TIPO_CERTIFICADO.ESCRITURAS_PUBLICAS"
                small
                class="ml-1"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon color="primary">mdi-information-outline</v-icon>
            </v-btn>
          </template>
          <span>Corresponde a la copia de escritura que dio origen al título citado. <br>El precio indicado corresponde a una garantía, en caso de saldos <br>a favor o en contra serán informados vía correo.</span>
        </v-tooltip>

        <p class=" mb-0 pl-2"> {{ certificado.tipo_certificado }} <span
            v-if="certificado.vigencia != -1">({{ certificado.vigencia }} Años)</span></p>
      </v-col>
      <v-col cols="6" md="4" class="d-flex justify-center align-center py-0">
        <v-btn class="px-0" style="min-width: 25px"
               small
               :disabled="mostrarMensajeComunidad && certificado.id_tipo_certificado == 1"
               elevation="0"
               height="26"
               tile
               outlined
               color="primary"
               @click="reducir(certificado)"
        >
          <v-icon small dark>
            mdi-minus
          </v-icon>
        </v-btn>
        <input type="text"
               class="input-spin"
               disabled
               v-model="certificado.numero_copias"
        >
        <v-btn
            outlined
            class="px-0"
            style="min-width: 25px"
            small
            :disabled="mostrarMensajeComunidad && certificado.id_tipo_certificado == 1"
            elevation="0"
            height="26"
            tile
            color="primary"
            @click="aumentar(certificado)"
        >
          <v-icon small dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="6" md="3" class="d-flex justify-center align-center py-0">
        <span :style="{color: certificado.numero_copias > 0 ? 'inherit' : '#6b666694'}">
          <span v-if="showTotal(certificado) > 0"> {{ showTotal(certificado) | clp }}</span>
          <span v-if="showTotal(certificado) == 0">  Por cotizar</span>
        </span>
      </v-col>
      <v-col
          class="mt-3"
          v-if="certificado.id_tipo_certificado == ID_TIPO_CERTIFICADO.ESCRITURAS_PUBLICAS && certificado.id_tipo_registro_FK == ID_TIPO_REGISTRO.ESCRITURAS_PUBLICAS">
        <v-alert color="info" outlined class="text-center " border="left">
          {{
            'En caso de ser posible, ¿desea que su copia incluya la certificación de vigencia? En caso de saldos a favor o a pagar serán informados vía correo'
          }}
          <v-radio-group
              v-model="certificacionDeVigencia"
              dense
              hide-details
              class="pt-2"
              style="margin: 0; padding: 0"
          >
            <div class="d-flex justify-center">
              <v-radio
                  label="No deseo la certificación de vigencia"
                  :value="false"
                  class="pa-0 ma-0"
                  color="#2196f3"
              ></v-radio>
              <v-radio
                  label="Sí deseo la certificación de vigencia"
                  :value="true"
                  class="pa-0 ma-0 ml-3"
                  color="#2196f3"
              ></v-radio>
            </div>
          </v-radio-group>
        </v-alert>
      </v-col>
    </v-row>
  </div>


</template>

<script>
import registros, {ID_AREA_AGRUPACION, ID_TIPO_CERTIFICADO, ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";

export default {
  name: "DocumentosTentativos",
  computed: {
    registros() {
      return registros
    }
  },
  components: {},

  data() {
    return {
      certificados: [],
      ID_TIPO_CERTIFICADO: ID_TIPO_CERTIFICADO,
      ID_AREA_AGRUPACION: ID_AREA_AGRUPACION,
      ID_TIPO_REGISTRO: ID_TIPO_REGISTRO,
      certificacionDeVigencia: false
    }
  },
  props: {
    escritura: {
      default: null
    },
    mostrarMensajeComunidad: {
      default: false
    },
    listaCertificados: {
      default: []
    },
    show: {
      default: null
    },
    hide: {
      default: null
    },
    model: {
      default: 0
    },
    numeroCarillas: {
      default: 0
    },
    precioCarillas: {
      default: 0
    }
  },
  created() {
    if (this.show != null) {
      this.certificados = this.listaCertificados.filter(f => {
        return f.id_tipo_certificado === this.show.id_tipo_certificado && f.id_tipo_registro_FK === this.show.id_tipo_registro_FK;
      });
      // if (this.show.id_tipo_certificado == ID_TIPO_CERTIFICADO.CERT_HIPOTECAS_GRAVAMENES) {
      //   this.$set(this.certificados[0], 'has_litigio', LITIGIO_PREDETERMINADO)
      // }

    } else if (this.hide != null) {
      this.certificados = this.listaCertificados.filter(f => {
        return f.id_tipo_certificado !== this.hide.id_tipo_certificado && f.id_tipo_registro_FK === this.hide.id_tipo_registro_FK;
      });
      //
      // this.certificados.map(c=>{
      //   if(c.id_tipo_certificado == ID_TIPO_CERTIFICADO.CERT_HIPOTECAS_GRAVAMENES){
      //     this.$set(c, 'has_litigio', LITIGIO_PREDETERMINADO)
      //   }
      // })

    } else {
      console.log("entra aqui y muestra: ", this.listaCertificados)
      this.certificados = this.listaCertificados;
    }
  },
  watch: {
    mostrarMensajeComunidad() {
      if (this.mostrarMensajeComunidad) {
        this.certificados.map(cer => {
          if (cer.id_tipo_certificado == 1) {
            cer.numero_copias = 0
          }
        })
      }
    },
    certificacionDeVigencia() {
      this.$emit('tieneCertificacionVigencia', this.certificacionDeVigencia);
    }
  },
  methods: {
    modificarLitigio(certificado) {
      this.$emit("modificarLitigio", certificado);
    },
    aumentar(certificado) {
      this.$emit("aumentar", certificado);
    },
    reducir(certificado) {

      this.$emit("reducir", certificado)

    },
    showTotal(certificado) {
      if (certificado.numero_copias > 0) {
        return certificado.numero_copias * (parseInt(certificado.precio) + (certificado.id_area_agrupacion == ID_AREA_AGRUPACION.COPIAS_PROPIEDAD ? parseInt(this.precioCarillas) : 0));
      } else {
        return (certificado.precio + (certificado.id_area_agrupacion == ID_AREA_AGRUPACION.COPIAS_PROPIEDAD ? parseInt(this.precioCarillas) : 0));
      }
    }
  }
}
</script>

<style scoped>

</style>
