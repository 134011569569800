<template>
    <div>
        <div v-if="listaSociedadesEncontradas.length === 0" class="pt-4">
            <v-alert
                type="warning"
                outlined
                border="left"
                :icon="false"
                class="text-center ">
                <p class="mb-0 font-md warning--text">
                    no se encontraron sociedades con el nombre ingresado
                </p>
            </v-alert>
        </div>
        <div v-if="listaSociedadesEncontradas.length > 0" class="pt-4">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th
                            class="text-center"
                            style="width: 40%"
                        >
                            Nombre de la Sociedad
                        </th>
                        <th class="text-center">
                            Naturaleza
                        </th>
                        <th class="text-center">
                            indice
                        </th>
                        <th style="width: 6%" class="text-center">IR</th>
                        <th style="width: 6%" class="text-center">DO</th>
                        <th style="width: 6%"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        v-for="(sociedad, index) in listaSociedadesEncontradas"
                        :key="index"
                    >
                        <td>{{ sociedad.nombre }}</td>
                        <td>{{ sociedad.naturaleza }}</td>
                        <td>{{ sociedad.foja }}-{{ sociedad.numero }}-{{ sociedad.anho}}</td>
                        <td>
                            <v-btn
                                icon
                                color="info"
                                @click="verDocumento(sociedad.foja, sociedad.numero, sociedad.anho, sociedad.documento)"
                                v-if="sociedad.documento !== ''"
                                :title="'Ver imagen referencial '"
                            >
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <v-btn
                                icon
                                color="info"
                                @click="verDiarioOficial(sociedad.link_diario_oficial)"
                                v-if="sociedad.link_diario_oficial !== '-' && sociedad.link_diario_oficial !== 'NOT_FOUND' && sociedad.link_diario_oficial !== ''"
                                title="Ver publicación Diario Oficial "
                            >
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </td>
                        <td>
                            <v-btn
                                icon
                                color="info"
                                @click="compararDocumento(sociedad)"
                                title="Solicitar documentos de la inscripción"
                            >
                                <v-icon>mdi-cart</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </div>

    </div>
</template>

<script>
import usuarioEntity from "@/model/usuario/usuario.entity";
import {EventBus} from "@/main";

export default {
    name: "PanelSociedadesEncontradas",

    props:{
      listaSociedadesEncontradas: null,
    },

    methods: {
        compararDocumento(sociedad){
            let contieneCostitucion = false
            let constitucionBoolean = sociedad.naturaleza.toLowerCase().includes('constitución');
            let constitucionBoolean2 = sociedad.naturaleza.toLowerCase().includes('constitucion');
            if(constitucionBoolean === true || constitucionBoolean2 === true){
                contieneCostitucion = true;
            }
            let serie = {
                foja: sociedad.foja,
                numero: sociedad.numero,
                anho: sociedad.anho,
                id_tipo_registro: 7,
                constitucion:contieneCostitucion,
            }
            console.log("serie :",serie)
            this.$emit("seleccionarItem", serie)
        },

        verDocumento(foja, numero, anho, idDocumento) {
            usuarioEntity.isSessionActive().then(res => {
                if (res) {
                    const serie = `${foja}-${numero}-${anho}`
                    window.open(`/visor-documentos/${serie}/${idDocumento}/d`, '_blank');
                } else {
                    EventBus.$emit("modal-login", true);
                }
            })
        },

        verDiarioOficial(link){
          window.open(link)
        },
    },

}
</script>

<style scoped>

</style>